* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .pr-m-wrapper {
    padding: 24px;
  }
  
  .pr-m-wrapper .pr-header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .pr-m-wrapper .pr-header {
    font-size: 2rem;
    font-weight: 600;
    font-family: Poppins;
  }
  
  .pr-m-wrapper .pr-outer-table {
    margin-right: 0;
    margin-left: 0;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 20px 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .pr-m-wrapper .table {
    font-family: Poppins;
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: #dee2e6;
    caption-side: bottom;
    border-collapse: collapse;
    font-size: 14px;
    min-width: 1300px;
  }
  
  .table > thead {
    vertical-align: bottom;
  }
  
  .table > tbody {
    vertical-align: inherit;
  }
  
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  
  .table > :not(caption) > * > * {
    padding: 10px 15px;
    color: #000;
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
    text-align: left;
  }
  
  .table tr th {
    white-space: nowrap;
  }
  
  .filter-cb {
    margin-bottom: 20px;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .filter-cb .bordered-button {
    padding: 8px 16px;
  }
  
  .filter-cb label {
    line-height: 1;
    font-size: 14px;
  }
  
  .filter-cb .input-set input {
    padding: 10px 15px;
    border: 1px solid #dee2e6;
  }
  
  .filter-cb .input-set input:hover {
    border: 1px solid #dee2e6;
  }