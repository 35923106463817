@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #333;
  --secondary-color: #444;
  --accent-color: #666;
}
.blogDetail_wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.details_main {
  height: 100%;
  width: 100%;
  background-color: beige;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.details_main p {
  font-family: Poppins;
  text-align: center;
}
.blogcard_title {
  font-size: 30px;
  font-weight: 600;
  margin: 0.7rem 0 0.4rem 0;
}
.blogcard_subtitle {
  font-size: 24px;
  font-weight: 500;
}
.blogcard_des {
  font-size: 18px;
  margin: 0.5rem 0;
}
.back_button {
  /* background-color: #e0eef6;
  border-radius: 40px;
  font-size: medium;
  padding: 2%; */
  margin-right: 10px;
  cursor: pointer;
}
.form_comp {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.form_comp label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  margin: 0.3rem 0 0.2rem 0;
  color: #333;
}
.form_comp input {
  padding: 0.5rem;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d1cece;
  font-size: 16px;
}
.form_comp select {
  padding: 0.5rem;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d1cece;
  font-size: 16px;
}
.form_comp textarea {
  padding: 0.5rem;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d1cece;
  font-size: 16px;
  font-family: Poppins;
}
.form_comp_subtitle {
  margin-top: 20px;
}
.confirm_btn {
  width: 200px;
  font-size: 18px;
  font-family: Poppins;
  margin: 0.7rem 0 2rem 0;
  padding: 0.35rem 0.9rem;
  background-color: #312968;
  color: white;
  border: none;
  border-radius: 5px;
}
.blog_heading {
  font-size: 30px;
  font-family: Poppins;
  font-weight: 600;
  padding: 2rem 0 0 3rem;
}
.modal_btn {
  width: 150px;
  font-size: 18px;
  font-family: Poppins;
  margin: 0.7rem;
  padding: 0.45rem 0.9rem;
  color: white;
  border: none;
  border-radius: 5px;
}

.tablecell {
  font-weight: 600;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.pointer {
  cursor: pointer;
}

.mt5 {
  margin-top: 5px;
}
.mt15 {
  margin-top: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.fw {
  width: 100%;
}
.mUpload-item {
  border: 1px solid #b9b9b9;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.MuiAccordion-root {
  border: 1px solid #b9b9b9;
}
.float-right {
  float: right;
}

.c1 {
  background-color: #17a2b8;
}
.c2 {
  background-color: #7367f0;
}
.c3 {
  background-color: yellowgreen;
}

.img-main-container {
  display: flex;
  justify-content: space-between;
}

.img-inner-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
}

.cb-o-cb {
  position: relative;
  font-family: Poppins;
  max-width: 900px;
  margin: 50px auto;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.form_o_cb {
  max-width: 1000px;
  margin: 50px auto 0;
}

.form_o_cb * {
  font-family: Poppins;
}

.i-cb {
  font-family: inherit;
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.full-w-cb {
  flex: 0 0 auto;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  margin-top: 20px;
}

.i-cb strong {
  display: flex;
  min-width: 150px;
  padding-left: 20px;
  padding-right: 20px;
}

.i-cb span {
  padding-left: 20px;
  padding-right: 20px;
}

.form_row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.form_col_6 {
  flex: 0 0 auto;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.form_container_box {
  position: relative;
  font-family: inherit;
}

.form_container_box label {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  display: block;
  font-family: inherit;
  margin-bottom: 5px;
}

.form_container_box input,
.form_container_box select,
.form_container_box textarea {
  border: 1px solid #d1cece;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  padding: 0.5rem;
  width: 100%;
}

.set_height,
.css-1fdsijx-ValueContainer {
  height: 44px;
}

.btn_o_c {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.loading_cb {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
