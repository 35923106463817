.store-container {
  padding: 20px;
  overflow-y: auto;
}
.bordered-button {
  border: 1px solid var(--primary-color) !important;
  min-width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  object-fit: contain;
}
.store-container .header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.header-container .header {
  font-size: 2rem;
  font-weight: 600;
  font-family: Poppins;
}
.header-container .btn-container {
  display: flex;
  gap: 15px;
  max-height: 35px;
}
.error {
  font-size: 12px;
  color: #f53232;
}
