.blogs-container {
  padding: 20px;
}
.blog-container {
  overflow-y: auto;
}
.bordered-button {
  border: 1px solid var(--primary-color) !important;
  min-width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  object-fit: contain;
}
.blog-container .blog-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.blog-header-container .blog-header {
  font-size: 2rem;
  font-weight: 600;
  font-family: Poppins;
}
.blog-header-container .btn-container {
  display: flex;
  gap: 15px;
  max-height: 35px;
}
