.create-form--container {
  padding-top: 20px;
}
.form-header-container {
  overflow-y: auto;
}
.bordered-button {
  border: 1px solid var(--primary-color) !important;
  min-width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  object-fit: contain;
}
.create-form--container .form-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.form-header-container .form-header {
  font-size: 2rem;
  font-weight: 600;
  font-family: Poppins;
}
.form-header-container .btn-container {
  display: flex;
  gap: 15px;
  max-height: 35px;
  margin-top: 5px;
}
.alt-tag-with-img {
  padding: 0.5rem;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d1cece;
  font-size: 16px;
}

.create-banneer-error {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #f53232;
}
.jodit-container .jodit-placeholder {
  color: #808080; /* Placeholder color */
  font-size: 14px; /* Placeholder font size */
}

.blog-faq {
  margin-top: 10px;
}
.blog-faq .css-ir5tdx {
  margin-left: 0px;
}
