.login-container.MuiContainer-root {
  padding: 4rem 20%;
}

.login-container form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
}

.login-header.MuiTypography-root {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0;
}

@media screen and (max-width: 600px) {
  .login-container.MuiContainer-root {
    padding: 4rem 2rem;
  }
}
