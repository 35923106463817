.text-editor {
  margin-bottom: "50px" !important;
}

.ql-editor {
  height: "5rem" !important;
}

.ql-container {
  height: 100px !important;
}
