.main-container.MuiGrid-root {
  min-height: 100vh;
}

.sidebar-container.MuiContainer-root {
  height: 100%;
  background-color: var(--primary-color);
  color: #fff;
  padding: 0;
}
.sidebar-options {
  margin-left: 30px !important;
}
.sidebar.MuiContainer-root {
  background-color: var(--primary-color);
  color: #fff;
  height: 100%;
  padding: 0;
}

.sidebar-header.MuiTypography-root {
  font-size: 28px;
  text-align: center;
  padding: 12px;
  background-color: var(--secondary-color);
}

.sidebar-treeview.MuiTreeView-root {
  padding: 1rem;
}

.sidebar-treeview .MuiTreeItem-content {
  padding: 6px 0px !important;
}

.menu-button.MuiIconButton-root {
  display: none;
}

.toolbar.MuiToolbar-root {
  min-height: 68px;
  justify-content: flex-end;
}

.logout-button.MuiButton-root {
  color: #fff;
  background-color: var(--secondary-color);
}

@media screen and (max-width: 600px) {
  .sidebar-container.MuiContainer-root {
    display: none;
  }

  .sidebar-drawer .MuiDrawer-paper {
    min-width: 60vw;
    height: 100%;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0;
  }

  .menu-button.MuiIconButton-root {
    display: unset;
    position: absolute;
    transform: translate(50%, 50%);
    z-index: 100;
    color: #fff;
  }
}
