.category-list,
.category-add,
.category-edit {
  padding: 24px;
}
.category-list .category-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.category-header-container .blog-header {
  font-size: 2rem;
  font-weight: 600;
  font-family: Poppins;
}

/* --------------------------Global css------------------------- */
.category-add .btn-flex,
.category-edit .btn-flex {
  display: flex;
  margin-right: -20px;
}
.category-add .btn-flex .btn-container,
.category-edit .btn-flex .btn-container {
  margin-right: 20px;
}

.category-add .MuiPaper-root.MuiPaper-elevation .MuiAccordionDetails-root,
.category-edit .MuiPaper-root.MuiPaper-elevation .MuiAccordionDetails-root {
  padding: 0px !important;
}

.category-add .MuiPaper-root.MuiPaper-elevation:last-of-type,
.category-edit .MuiPaper-root.MuiPaper-elevation:last-of-type {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 60% !important;
  transform: translate(-50%, -50%) !important;
}
.Quil-one,
.Quil-two {
  padding: 0px 16px 16px;
}

.category-add .btn-group,
.category-edit .btn-group {
  display: flex;
  padding: 8px 16px 16px;
  justify-content: end;
}

.category-add .quil-close-btn,
.category-edit .quil-close-btn {
  margin-right: 10px;
}

/* category add component style */

.category-add .category-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.container {
  margin-top: 60px;
  display: flex;
  align-items: center;
}
.label {
  /* font-size: 20px; */
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  margin: 0.3rem 0 0.2rem 0;
  color: #333;
  width: 25%;
}
.input {
  width: 100%;
  padding: 8px;
}

.Quil-maodal-header {
  background-color: ghostwhite;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-weight: 600;
}

.Quil-one .title {
  font-weight: 600;
}
.Quil-two .title {
  font-weight: 600;
}

/* ------------------Edit Category style-------- */

.category-edit .category-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 600;
  font-family: Poppins;
}

.category-edit .container {
  align-items: center;
}
.question {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  display: grid;
  grid-template-columns: 100px 500px;
  column-gap: 10px;
}
.answer {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  margin-top: 25px;
  display: grid;
  grid-template-columns: 100px 500px 50px 50px;
  column-gap: 10px;
}

.quest-header {
  margin-right: 40px;
}

.category-edit .Quil-one,
.Quil-two {
  padding: 0px 16px 16px;
  font-family: Poppins;
  font-size: 16px;

  margin: 0.3rem 0 0.2rem 0;
  color: #333;
}

.category-add .Quil-one .ql-container,
.Quil-two .ql-container {
  height: 80px !important;
}
.category-edit .Quil-one,
.Quil-two title {
  font-family: Poppins;
  font-size: 16px;
  margin: 0.3rem 0 0.2rem 0;
  color: #333;
}

/* --------------------category-card---------------------- */
.category-card-container {
  width: calc(25% - 20px);
  margin: 10px;
}

.card-que-body {
  /* padding: 0.5rem;
  border-radius: 4px; */
  /* outline: none; */
  /* border: 1px solid #d1cece; */
  /* font-size: 16px; */
}

.faqs-error {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #f53232;
}
