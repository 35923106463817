.testimonial-list {
  padding: 24px;
}
.testimonial-list .testimonial-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.testimonial-header-container .testimonial-header {
  font-size: 2rem;
  font-weight: 600;
  font-family: Poppins;
}

/* --------------------------Global css------------------------- */

.testimonial .btn-flex .btn-container {
  margin-right: 20px;
}

.form_comp_checkbox {
  display: flex;
  align-items: center;
}

.form_comp_checkbox .label {
  width: 15%;
}

/* --------------------Testimonial-card---------------------- */
.testimonial-card-container {
  width: calc(25% - 20px);
  margin: 10px;
}

.testimonial-card-container .css-sgoict-MuiCardHeader-action {
  margin-top: 0px;
}

.faqs-error {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #f53232;
}
